import styled from "styled-components";
import { Drawer } from "artisn-ui-react";

import { ShoppingCartDrawerStyledProps as Props } from "./ShoppingCartDrawer.types";
import { getMaxWidth } from "utils/styling.utils";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const ShoppingCartDrawerStyled = styled(Drawer)<Props>`
  &.ShoppingCartDrawer {
    padding: 2.4rem 1.6rem;
    width: clamp(32rem, ${getMaxWidth()}, 40rem);
    min-height: var(--sizes-page-maxHeight);
    max-height: var(--sizes-page-maxHeight);
  }

  .ShoppingCartDrawer {
    &__store {
      display: flex;
      align-items: center;
      padding-bottom: 3.2rem;
      font-weight: 600;

      svg {
        margin-right: 1.2rem;
      }
    }

    &__close {
      position: absolute;
      right: 1.6rem;
      top: 1.6rem;
      cursor: pointer;
      z-index: 2;
    }

    &__share {
      margin-left: 2rem;
    }

    &__title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: var(--palette-black-s0-l10);
      padding: 2rem 0;
      display: flex;
      align-items: center;
    }

    &__cart {
      overflow-y: auto;
      scrollbar-width: none;
    }

    &__cart::-webkit-scrollbar {
      width: 0;
    }

    &__coupon {
      padding-bottom: 2rem;
      padding-right: 0.8rem;

      .RedeemCoupon {
        &__badge {
          flex: 1;
        }

        &__button-wallet {
          width: 100%;
        }
      }
    }

    &__order {
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l35);
      line-height: 1.6rem;
      padding: 0.8rem 2rem;
    }

    &__empty-button {
      font-size: 1.2rem;
      line-height: 1.6rem;
      width: 8rem;
      padding: 0;
      margin: 1.6rem 0;
      margin-left: auto;
    }

    &__add-button {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 600;
      justify-content: flex-start;
      padding: 1.2rem 2.4rem;
      margin: 2rem 0;

      @media (max-width: ${mobile}px) {
        padding: 1.2rem;
        width: 100%;
      }

      svg {
        margin-right: 0.8rem;

        path {
          fill: var(--palette-primary);
        }
      }
    }

    &__summary {
      margin-bottom: 3.2rem;
    }

    &__pay-button {
      background-color: var(--palette-white);
      width: 100%;
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      justify-content: center;
      padding: 1.6rem;
      box-shadow: 0 -0.1rem 0.8rem 0.1rem var(--palette-gray-s0-l20-a08);
      z-index: 1;

      .CartPayButton,
      .Cart__summary__button {
        width: 100%;
      }
    }
  }

  .EmptyCart {
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default ShoppingCartDrawerStyled;
