import styled from "styled-components";

import { AddToCartButtonStyledProps as Props } from "./AddToCartButton.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const AddToCartButtonStyled = styled.div<Props>`
  .AddToCartButton {
    &__text {
      color: var(--palette-text-white);

      &--desktop {
        @media (max-width: ${tablet}px) {
          display: none;
        }
      }

      &--mobile {
        @media (min-width: ${tablet + 1}px) {
          display: none;
        }
      }
    }

    &__separator {
      color: var(--palette-text-white);
    }
  }

  .Button {
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
  }
`;

export default AddToCartButtonStyled;
