import styled from "styled-components";

import { CrossaleModalStyledProps as Props } from "./CrossaleModal.types";
import Modal from "components/global/Modal/Modal";
import { getMaxWidth } from "utils/styling.utils";

const CrossaleModalStyled = styled(Modal)<Props>`
  padding-top: 3.2rem;
  width: min(${getMaxWidth()}, 37.6rem);

  .CrossaleModal {
    &__header {
      flex: 1;
      padding: 0 2.4rem;
      padding-bottom: 1.6rem;
    }

    &__title {
      font-size: 2rem;
      line-height: 2.8rem;
      padding-top: 0;
      color: var(--palette-black-s0-l10);
      text-align: center;
    }

    &__description {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: var(--palette-gray-s0-l35);
      text-align: center;
      padding: 0 2.4rem;
      padding-top: 0.8rem;
    }

    &__actions {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 2.4rem;
      background-color: var(--palette-white);
      position: fixed;
      bottom: 0;
      z-index: 2;
    }

    &__products {
      padding-top: 3.2rem;
      overflow-y: auto;
      padding-bottom: 9.6rem;
    }

    &__product {
      padding: 0.8rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__button {
      width: 100%;
      padding: 1.2rem;
      font-size: 1.6rem;
    }
  }
`;

export default CrossaleModalStyled;
