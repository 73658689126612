import { useEffect, useState } from "react";
import { events } from "@artisan-commerce/analytics-capacitor";
import { createShoppingCart, findProduct } from "artisn/shopping-cart";
import { addProduct, removeProduct, checkInit } from "artisn/shopping-cart";
import { CartProduct } from "artisn/types";

import CONSTANTS from "config/constants";
import useGeo from "contexts/geo/geo.hooks";
import useShippingCost from "hooks/useShippingCost";
import useAuth from "contexts/auth/auth.context.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import { UseAddToCartWishlistProps } from "./AddToWishlistButton.types";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";
import { fetchStoreDetails } from "services/stores/stores.service";
import { PetDetails } from "components/product/Product/Product.types";

const { CONTENT_TYPE } = CONSTANTS.ARTISN;
const { logAddProductToWishlist } = events.shoppingCart;
const { logRemoveProductFromWishlist } = events.shoppingCart;

export const useAddToWishlist = (props: UseAddToCartWishlistProps) => {
  const { product, onFinish, config } = props;
  const { shoppingCartName } = config;
  const { selectedCatalogueId } = useCatalogues();
  const shippingCost = useShippingCost();
  const { selectedCoordinates } = useGeo();
  const { isAnonymous } = useAuth();
  const { wishlist } = useShoppingCart();
  const { productId } = product ?? {};
  const [inCart, setInCart] = useState(false);
  const { storeId } = (product as PetDetails) ?? {};

  const { lat, lng } = selectedCoordinates ?? {};

  const onClick = async () => {
    if (!product || !checkInit()) return;

    const { id: cartId, name: cartName } = wishlist ?? {};

    if (!wishlist) {
      await createShoppingCart(
        {
          anonymous: isAnonymous
        },
        {
          channelId: +selectedCatalogueId,
          shippingCost,
          latitude: lat ?? 0,
          longitude: lng ?? 0,
          name: shoppingCartName
        }
      );
    }

    try {
      const store = await fetchStoreDetails(storeId!);

      if (inCart) {
        removeProduct(
          { ...product, productId: product.productId?.toString() },
          {
            shoppingCartName,
            anonymous: isAnonymous,
            store
          }
        );
        setInCart(false);

        if (cartId && product) {
          logRemoveProductFromWishlist({
            cartId,
            product: product as CartProduct,
            contentType: CONTENT_TYPE
          });
        }

        onFinish?.();
        return;
      }

      addProduct(
        { ...product, productId: product.productId?.toString() },
        {
          amount: 1,
          store,
          shoppingCartName,
          anonymous: isAnonymous
        }
      );
      setInCart(true);

      if (cartId && cartName) {
        logAddProductToWishlist({
          cartId,
          cartName,
          product: product as CartProduct,
          contentType: CONTENT_TYPE
        });
      }

      onFinish?.();
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    if (
      typeof productId === "undefined" ||
      typeof isAnonymous === "undefined" ||
      isAnonymous ||
      !checkInit()
    )
      return;
    (async () => {
      const inCart = !!(await findProduct({
        productId: productId.toString(),
        shoppingCartName
      }));
      setInCart(inCart);
    })();

    return () => setInCart(false);
  }, [productId, shoppingCartName, wishlist, isAnonymous]);

  return { onClick, inCart };
};
