import React from "react";

import Styles from "./CrossaleModal.styles";
import { CrossaleModalProps as Props } from "./CrossaleModal.types";
import HorizontalProductCard from "../HorizontalProductCard/HorizontalProductCard";
import Button from "components/global/Button/Button";

const CrossaleModal: React.FC<Props> = props => {
  const { opened, products, onClose, onSuccess, onClick } = props;

  const headerNode = (
    <div className="CrossaleModal__header">
      <p className="CrossaleModal__title">
        Otras opciones que podrían interesarte
      </p>
      <p className="CrossaleModal__description">
        Otras personas han elegido estas opciones
      </p>
    </div>
  );

  return (
    <Styles
      className="CrossaleModal"
      opened={opened}
      header={headerNode}
      onClose={onClose}
      closeOnClickOutside={false}
    >
      <div className="CrossaleModal__products">
        {products.map(product => {
          const { productId } = product;
          return (
            <HorizontalProductCard
              className="CrossaleModal__product"
              product={product}
              onClick={() => onClick(product)}
              key={productId}
            />
          );
        })}
      </div>
      <div className="CrossaleModal__actions">
        <Button
          className="CrossaleModal__button"
          color="black"
          onClick={() => {
            onClose();
            onSuccess();
          }}
        >
          No gracias
        </Button>
      </div>
    </Styles>
  );
};

CrossaleModal.defaultProps = {};

export default CrossaleModal;
