import React from "react";

import Styles from "./AddToCartButton.styles";
import { AddToCartButtonProps as Props } from "./AddToCartButton.types";
import { useAddToCart } from "./AddToCartButton.hooks";
import Button from "components/global/Button/Button";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";
import { createContinueCheckoutNotification } from "utils/notifications.utils";

const AddToCartButton: React.FC<Props> = props => {
  const { className, disabled, isAddToCart } = props;
  const { onClick, isAdding } = useAddToCart({ ...props });
  const { shoppingCart } = useShoppingCart();
  const noOKClass = "AddToCartButton__btn__no-ok";

  const clickHandler = () => {
    if (shoppingCart && Object.keys(shoppingCart.stores).length) {
      createContinueCheckoutNotification();
      return;
    }
    onClick();
  };

  return (
    <Styles className={`AddToCartButton ${className}`}>
      <Button
        className={`${noOKClass}`}
        onClick={clickHandler}
        disabled={disabled}
        isLoading={isAdding || isAddToCart}
      >
        ¡Lo adopto!
      </Button>
    </Styles>
  );
};

AddToCartButton.defaultProps = {
  className: ""
};

export default AddToCartButton;
