import styled from "styled-components";

import { RedeemCouponStyledProps as Props } from "./RedeemCoupon.types";

const RedeemCouponStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;

  .Badge {
    &__value {
      min-width: 2.4rem;
      height: 2rem;
      font-size: 1.2rem;
      z-index: 1;
      padding: 0 0.4rem;
    }
  }

  .RedeemCoupon {
    &__container {
      display: flex;
      align-items: flex-end;
    }

    &__redeem-error {
      padding-top: 0.8rem;
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
    }

    &__button-wallet {
      padding: 1.4rem 2rem;
    }

    &__input {
      width: calc(100% - 8rem);
      z-index: 1;
    }

    &__badge {
      margin-left: 0.8rem;
      z-index: 1;
    }
  }
`;

export default RedeemCouponStyled;
