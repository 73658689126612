import CONSTANTS from "config/constants";
import styled from "styled-components";

import { ProductDetailsImageStyledProps as Props } from "./ProductDetailsImage.types";

const { BREAKPOINTS } = CONSTANTS;
const { mobile, tablet, desktop } = BREAKPOINTS;

const ProductDetailsImageStyled = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${mobile}px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 28.8rem;
  }

  .ProductDetailsImage {
    &__thumbnail-carousel {
      padding-right: 1.6rem;

      @media (max-width: ${mobile}px) {
        padding-right: 0;
      }

      & .ThumbnailCarousel__slider {
        justify-content: center;

        &:first-child {
          border-bottom-left-radius: 4rem;
        }

        &:last-child {
          border-top-left-radius: 4rem;
        }

        @media (max-width: ${mobile}px) {
          margin-top: 0.8rem;
          height: 9rem;
          &:first-child {
            border-bottom-left-radius: 2rem;
          }

          &:last-child {
            border-bottom-right-radius: 2rem;
            border-top-left-radius: unset;
          }
        }
      }

      .ThumbnailCarousel__slider-item {
        ${({ quantity }) =>
          quantity && quantity > 2
            ? `

            &:nth-child(3) {
              position: relative;
            }

            &:nth-child(3)::after {
              content: "Ver todas";
              background-color: #00000050;
              position: absolute;
              inset: 0;
              color: var(--palette-white);
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.8rem;

              @media (max-width: 580px) {
                font-size: 1.4rem;
              }
            }`
            : null}

        @media (max-width: ${mobile}px) {
          height: unset;
        }
      }

      &--desktop {
        border-bottom-left-radius: 5rem;
        border-top-left-radius: 5rem;

        @media (max-width: ${mobile}px) {
          display: none;
        }
      }

      &--mobile {
        @media (min-width: ${mobile + 1}px) {
          display: none;
        }
      }

      &--modal {
        width: 100%;
        height: 100%;
        position: absolute;
      }

      &--thumbnail {
        position: absolute;
      }
    }

    &__image {
      &--tablet {
        border-bottom-right-radius: 4rem;
        border-top-right-radius: 4rem;

        @media (min-width: ${tablet + 1}px) {
          display: none;
        }
        @media (max-width: ${mobile}px) {
          display: none;
        }
      }

      &--mobile {
        @media (min-width: ${mobile + 1}px) {
          display: none;
        }

        @media (max-width: ${mobile}px) {
          border-top-right-radius: 2rem;
          border-top-left-radius: 2rem;
        }
      }
    }

    &__big-image {
      position: relative;

      &--desktop {
        border-bottom-right-radius: 4rem;
        border-top-right-radius: 4rem;

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__light-box {
      &--desktop {
        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }
    &__button {
      background-color: var(--palette-white);
      border: unset;
      color: var(--palette-primary);
      font-size: 1.6rem;
      border-radius: 1.2rem;
      gap: 0.8rem;
      position: absolute;
      z-index: 1;
      bottom: 2rem;
      right: 4.8rem;
      height: 5rem;

      &:hover {
        border: unset !important;
      }

      @media (max-width: 1200px) {
        right: 2.4rem;
      }

      @media (max-width: ${tablet}px) {
        bottom: 2rem;
      }

      @media (max-width: 580px) {
        bottom: 22rem;
        right: 1.6rem;
      }

      @media (max-width: ${mobile}px) {
        top: 1.2rem;
        right: 1.2rem;
        height: 4rem;
        padding: 0 1rem;
      }
    }

    &__box {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: 2.2rem;
      margin-right: 1rem;
      height: 15rem;
      width: 15rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 50%);
      overflow: hidden;
      border-bottom-left-radius: 5rem;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: ${desktop}px) {
        margin-left: 10rem;
      }
    }

    &__show {
      color: var(--palette-white);
      font-size: 1.8rem;
    }
  }
`;

export default ProductDetailsImageStyled;
