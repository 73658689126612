import React, { useEffect, useState } from "react";
import { ThumbnailCarousel } from "artisn-ui-react";
import { Image, LightboxImage } from "artisn-ui-react";

import Styles from "./ProductDetailsImage.styles";
import { ProductDetailsImageProps as Props } from "./ProductDetailsImage.types";
import { imageConfigurations } from "./ProductDetailsImage.helpers";
import { imageLightboxConfigurations } from "./ProductDetailsImage.helpers";
import { imagePrincipalConfiguration } from "./ProductDetailsImage.helpers";
import { imageModalConfiguration } from "./ProductDetailsImage.helpers";
import { responsiveMeasure } from "./ProductDetailsImage.helpers";
import Modal from "components/global/Modal/Modal";
import Button from "components/global/Button/Button";
import useWindowSize from "hooks/useWindowSize";
import CONSTANTS from "config/constants";

import ExpandIconSVG from "../../../../public/assets/images/expand-icon.svg";

const { DEFAULT_IMAGE } = CONSTANTS.ARTISN;

const ProductDetailsImage: React.FC<Props> = props => {
  const { images } = props;
  const [imageIndex, setImageIndex] = useState(0);
  const [showLightbox, setShowLightbox] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { width } = useWindowSize();

  const doubleClickHandler = (index: number) => {
    setImageIndex(index);
    setShowModal(true);
  };

  const seeAllHandler = (index: number) => {
    setImageIndex(index);
    if (index === 2) {
      setShowModal(true);
    }
  };

  const renderMobileNode = () => {
    if (!images || !images.length) return null;

    const selectedImage = images[imageIndex];

    // TODO: add default image to ThumbnailCarousel when artisn-ui-react is updated
    return (
      <>
        <Image
          alt={selectedImage.name}
          image={selectedImage}
          config={imageConfigurations(width)}
          placeholder="blur"
          className="ProductDetailsImage__image--mobile"
          errorImage={DEFAULT_IMAGE}
        />
        <ThumbnailCarousel
          slideWidth={90}
          slideHeight={90}
          images={images.slice(0, 3)}
          className="ProductDetailsImage__thumbnail-carousel ProductDetailsImage__thumbnail-carousel--mobile"
          width={288}
          height={98}
          onClickItem={seeAllHandler}
          onHoverItem={setImageIndex}
          showNavigationButtons={false}
          gap={8}
          errorImage={DEFAULT_IMAGE}
        />
      </>
    );
  };

  const renderDesktopNode = () => {
    if (!images || !images.length) return null;

    const selectedImage = images[imageIndex];
    return (
      <>
        <ThumbnailCarousel
          slideWidth={responsiveMeasure(92, 126, 150, width)}
          slideHeight={responsiveMeasure(92, 126, 150, width)}
          images={images.slice(0, 3)}
          height={responsiveMeasure(288, 400, 490, width)}
          className="ProductDetailsImage__thumbnail-carousel ProductDetailsImage__thumbnail-carousel--desktop"
          vertical
          onClickItem={seeAllHandler}
          onHoverItem={setImageIndex}
          onDoubleClickItem={doubleClickHandler}
          showNavigationButtons={false}
          gap={responsiveMeasure(5, 10, 20, width)}
          errorImage={DEFAULT_IMAGE}
        />
        <Image
          alt={selectedImage.name}
          image={selectedImage}
          config={imageConfigurations(width)}
          placeholder="blur"
          className="ProductDetailsImage__image--tablet"
          errorImage={DEFAULT_IMAGE}
        />
        <Image
          className="ProductDetailsImage__big-image--desktop"
          alt={selectedImage.name}
          image={selectedImage}
          config={imagePrincipalConfiguration}
          errorImage={DEFAULT_IMAGE}
        />
        <Button
          color="primary-light"
          className="ProductDetailsImage__button"
          onClick={() => setShowModal(true)}
        >
          <ExpandIconSVG /> Abrir
        </Button>
        {showLightbox ? (
          <LightboxImage
            className="ProductDetailsImage__light-box--desktop"
            images={images}
            value={imageIndex}
            onClose={() => setShowLightbox(false)}
            onChange={setImageIndex}
            imageConfig={{
              alt: "Lightbox image preview",
              config: imageLightboxConfigurations(width)
            }}
            errorImage={DEFAULT_IMAGE}
          />
        ) : null}
        <Modal
          opened={showModal}
          onClose={() => setShowModal(false)}
          className="ProductDetailsImage__modal"
        >
          <Image
            alt={selectedImage.name}
            image={selectedImage}
            config={imageModalConfiguration}
            placeholder="blur"
            className="ProductDetailsImage__modal__image"
            errorImage={DEFAULT_IMAGE}
          />
          <ThumbnailCarousel
            slideWidth={106}
            slideHeight={106}
            images={images}
            className="ProductDetailsImage__modal__carousel"
            width={200}
            gap={16}
            errorImage={DEFAULT_IMAGE}
          />
        </Modal>
      </>
    );
  };

  // TODO: change when artisn-ui-react is updated
  useEffect(() => {
    if (!showModal || !images?.length) return;
    let carousel: HTMLDivElement | null = null;
    const actionClassName = "ThumbnailCarousel__action";
    const svgClassName = "ThumbnailCarousel__action--180";

    const thumbnailClick = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const isButtonRight =
        target?.firstElementChild?.classList?.value === svgClassName;
      const isButtonLeft =
        target.classList.value.indexOf(actionClassName) !== -1 &&
        !isButtonRight;

      if (isButtonRight && imageIndex < images?.length - 1) {
        setImageIndex(prev => ++prev);
        return;
      }

      if (isButtonLeft && imageIndex > 0) {
        setImageIndex(prev => --prev);
      }
    };

    setTimeout(() => {
      carousel = document.querySelector(
        ".ProductDetailsImage__modal__carousel"
      );
      carousel?.addEventListener("click", thumbnailClick);
    }, 500);

    return () => carousel?.removeEventListener("click", thumbnailClick);
  }, [images?.length, showModal, imageIndex]);

  useEffect(() => {
    if (!showModal) return;
    setTimeout(() => {
      const images = document.querySelectorAll(
        ".ProductDetailsImage__modal__carousel .Image__img"
      );
      images.forEach((node, index) => {
        node.className = "Image__img";
        if (index === imageIndex) {
          node.className = "Image__img Image__img--active";
        }
      });
    }, 500);
  }, [showModal, imageIndex]);

  return (
    <Styles className="ProductDetailsImage" quantity={images?.length}>
      {renderDesktopNode()}
      {renderMobileNode()}
    </Styles>
  );
};

ProductDetailsImage.defaultProps = {};

export default ProductDetailsImage;
