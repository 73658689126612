// Category Page helper functions and data
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { desktop, tablet } = BREAKPOINTS;

export const imageConfigurations = (width: number) => {
  if (width <= 580) {
    return { width: 288, height: 288 };
  }

  if (width <= tablet) {
    return { width: 400, height: 400 };
  }

  return { width: 490, height: 490 };
};

export const imageLightboxConfigurations = (width: number) => {
  if (width > tablet && width < desktop) {
    return { width: 600, height: 600 };
  }

  if (width < tablet) {
    return { width: 400, height: 400 };
  }

  return { width: 800, height: 800 };
};

export const imagePrincipalConfiguration = {
  width: 490,
  height: 490
};

export const imageModalConfiguration = {
  width: 1140,
  height: 1140
};

export const responsiveMeasure = (
  minValue: number,
  mediumValue: number,
  maxValue: number,
  currentWidth: number
) => {
  const isMobile = currentWidth <= tablet;
  const isPreMobile = currentWidth <= tablet - 188;
  if (isMobile) {
    if (isPreMobile) {
      return minValue;
    } else {
      return mediumValue;
    }
  } else {
    return maxValue;
  }
};
