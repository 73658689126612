import { useCallback, useMemo, useState } from "react";
import { events } from "@artisan-commerce/analytics-capacitor";
import { createShoppingCart, getShoppingCart } from "artisn/shopping-cart";
import { addProduct, replaceProduct } from "artisn/shopping-cart";

import useStores from "contexts/stores/stores.context.hooks";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";
import useShippingCost from "hooks/useShippingCost";
import useGeo from "contexts/geo/geo.hooks";
import CONSTANTS from "config/constants";
import { UseAddToCartProps } from "./AddToCartButton.types";
import { addToCartField, defaultConfig } from "./AddToCartButton.helpers";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import useProducts from "contexts/products/products.context.hooks";
import { saveProductPreference } from "utils/product.utils";
import { getBenefitProductId } from "utils/common.utils";
import { useFetchUser } from "services/user/user.service.hooks";
import { fetchStoreDetails } from "services/stores/stores.service";
import { CartPet } from "components/profileWishlist/ProfileWishlist/ProfileWishlist.types";

const { CONTENT_TYPE, SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;
const { logAddProductToCart, logUpdateProductInCart } = events.shoppingCart;

export const useAddToCart = (props: UseAddToCartProps) => {
  const { form, onFinish, config = defaultConfig } = props;
  const { selectedStore } = useStores();
  const { selectedProduct, setSelectedProduct } = useProducts();
  const { shoppingCart, temporalBenefit } = useShoppingCart();
  const { selectedCatalogueId } = useCatalogues();
  const shippingCost = useShippingCost();
  const { amount, comment } = config;
  const { selectedCoordinates } = useGeo();
  const [isAdding, setIsAdding] = useState(false);
  const { isAnonymous } = useAuth();
  const { data: user } = useFetchUser();
  const { uid: userUid } = user ?? {};

  const shouldReplace = !!selectedProduct;
  const { product, validate } = form ?? {};
  const { storeId } = (product as CartPet) ?? {};
  const { lat, lng } = selectedCoordinates ?? {};

  const onClick = useCallback(async () => {
    setIsAdding(true);

    if (!product || !validate) {
      setIsAdding(false);
      return;
    }

    try {
      const store = await fetchStoreDetails(storeId!);
      if (shouldReplace) {
        const { id, name, benefits } =
          (await getShoppingCart({
            shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
            anonymous: isAnonymous
          })) ?? {};

        const selectedBenefit = benefits ? benefits[0] : undefined;
        const benefitProductId = getBenefitProductId(selectedBenefit);
        /* If the product being modified is not equal to the benefitId in the
          cart, replace it and continue normally. If not, do nothing. */
        if (benefitProductId !== product.productId) {
          await replaceProduct(product, {
            amount,
            comment,
            store,
            shoppingCartName: name,
            anonymous: isAnonymous
          });
          saveProductPreference(form, userUid);
          setSelectedProduct(undefined);

          if (selectedStore && id && name) {
            logUpdateProductInCart({
              cartId: id,
              cartName: name,
              product,
              store,
              fields: addToCartField,
              contentType: CONTENT_TYPE
            });
          }
        }
      } else {
        if (!shoppingCart) {
          await createShoppingCart(
            {
              anonymous: isAnonymous
            },
            {
              channelId: +selectedCatalogueId,
              shippingCost,
              latitude: lat ?? 0,
              longitude: lng ?? 0,
              name: SHOPPING_CART_DEFAULT_NAME
            }
          );
        }
        const { id, name } =
          (await getShoppingCart({
            shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
            anonymous: isAnonymous
          })) ?? {};

        /* If a benefit of type PRODUCT exists in context, prevent addProduct
          from firing up. If this condition is removed, a benefit product is added
          first and another product is added to the cart too, removing the
          benefitId key from the product and breaking the coupons functionality.
        */
        if (!temporalBenefit) {
          await addProduct(product, {
            amount,
            store,
            comment,
            shoppingCartName: name,
            anonymous: isAnonymous
          });
          saveProductPreference(form, userUid);
        }

        /* The add product to cart event is kept for coupons because in the
        background, the applyBenefit function runs addProduct too. */
        if (selectedStore && id && name) {
          logAddProductToCart({
            cartId: id,
            cartName: name,
            product,
            store,
            contentType: CONTENT_TYPE
          });
        }
      }

      await onFinish?.();

      setIsAdding(false);
    } catch (e) {
      console.error(e.message);
    }
  }, [
    product,
    validate,
    shouldReplace,
    onFinish,
    amount,
    comment,
    selectedStore,
    setSelectedProduct,
    shoppingCart,
    form,
    temporalBenefit,
    selectedCatalogueId,
    shippingCost,
    lat,
    lng,
    isAnonymous,
    userUid,
    storeId
  ]);

  return useMemo(() => {
    return { shouldReplace, onClick, isAdding };
  }, [isAdding, onClick, shouldReplace]);
};
