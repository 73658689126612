import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import { formatByCurrency, ShareButton } from "artisn-ui-react";
import { getShoppingCartProducts, removeBenefit } from "artisn/shopping-cart";
import { emptyShoppingCart } from "artisn/shopping-cart";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import { ValidateShoppingCartResult } from "artisn/shopping-cart";
import { events } from "@artisan-commerce/analytics-capacitor";

import Styles from "./ShoppingCartDrawer.styles";
import { ShoppingCartDrawerProps as Props } from "./ShoppingCartDrawer.types";
import RedeemCoupon from "components/Cart/RedeemCoupon/RedeemCoupon";
import CONSTANTS from "config/constants";
import useShippingCost from "hooks/useShippingCost";
import { currencyOptionsDefault } from "components/categories/FiltersAccordion/FilterAccordion.helpers";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";
import useCountries from "contexts/countries/countries.hooks";
import Button from "components/global/Button/Button";
import { getFullPath, notify } from "utils/common.utils";
import useStores from "contexts/stores/stores.context.hooks";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import { usePutCancelOrder } from "services/orders/orders.service.hooks";
import { useFetchIncompleteOrders } from "services/orders/orders.service.hooks";
import ShoppingCartSummary from "components/checkout/ShoppingCartSummary/ShoppingCartSummary";
import useAuth from "contexts/auth/auth.context.hooks";
import EmptyCart from "components/Cart/EmptyCart/EmptyCart";
import CouponDetail from "components/coupons/CouponDetail/CouponDetail";
import CartProducts from "components/Cart/CartProducts/CartProducts";
import CartPayButton from "components/Cart/CartPayButton/CartPayButton";
import { useFetchRecommendedProducts } from "services/products/products.service.hooks";
import UpsaleModal from "components/global/UpsaleModal/UpsaleModal";
import useWindowSize from "hooks/useWindowSize";

import PlusSVG from "../../../../../public/assets/images/plus.svg";
import CloseSVG from "../../../../../public/assets/images/close.svg";
import StoreSVG from "../../../../../public/assets/images/store.svg";

const { ARTISN, FEATURE_FLAGS, BREAKPOINTS } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME } = ARTISN;
const { logClearCart } = events.shoppingCart;
const { logViewCart } = events.shoppingCart;
const { WITH_COUPONS, WITH_SHARE_SHOPPING_CART } = FEATURE_FLAGS;
const { WITH_UPSALE_MODAL, WITH_PURCHASE } = FEATURE_FLAGS;
const { mobile } = BREAKPOINTS;

const ShoppingCartDrawer: React.FC<Props> = props => {
  const { onClose, opened } = props;
  const [errors, setErrors] = useState<ValidateShoppingCartResult>();
  const [disabled, setDisabled] = useState(false);
  const router = useRouter();
  const { shoppingCart, setTemporalBenefit } = useShoppingCart();
  const { analyticsInitialized } = useAnalytics();
  const viewCartLogged = useRef(false);
  const { selectedCountry } = useCountries();
  const { currency } = selectedCountry;
  const [removeBenefitError, setRemoveBenefitError] = useState("");
  const { width, height } = useWindowSize();

  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const totals = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartTotal(shoppingCart);
  }, [shoppingCart]);
  const { uid, isAnonymous } = useAuth();
  const { selectedStore } = useStores();
  const { storeId } = selectedStore ?? {};
  const { data: incompleteOrders } = useFetchIncompleteOrders();
  const { mutate: cancelOrder } = usePutCancelOrder();
  const empty = (products && products.length === 0) || !shoppingCart;
  const { id: shoppingCartId, benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const shippingCost = useShippingCost();
  const { data: recommendedProducts } = useFetchRecommendedProducts();
  const [openUpsale, setOpenUpsale] = useState(WITH_UPSALE_MODAL);

  const closeHandler = () => {
    onClose();
    setOpenUpsale(true);
  };

  const removeHandler = async () => {
    if (!selectedBenefit) return;

    try {
      setRemoveBenefitError("");
      const { benefitId } = selectedBenefit;

      const product = products?.find(
        product => product.benefitId === benefitId
      );

      await removeBenefit({
        benefitId,
        shippingCost,
        product,
        productConfig: {
          store: selectedStore
        },
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous
      });
      setTemporalBenefit(undefined);
    } catch (e) {
      notify(e, "Remove benefit");
      setRemoveBenefitError(e.message);
    }
  };

  const emptyCartHandler = () => {
    if (!shoppingCartId) return;

    logClearCart({
      cartId: shoppingCartId
    });
    emptyShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous
    });
    setTemporalBenefit(undefined);
  };

  useEffect(() => {
    if (!storeId) return;
    if (!products?.length || errors) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [errors, products?.length, storeId]);

  useEffect(() => {
    if (
      !shoppingCartId ||
      viewCartLogged.current ||
      !analyticsInitialized ||
      !products?.length ||
      !opened
    ) {
      return;
    }

    logViewCart({
      cartId: shoppingCartId,
      products: products
    });
    viewCartLogged.current = true;
  }, [analyticsInitialized, products, shoppingCartId, opened]);

  useEffect(() => {
    if (!incompleteOrders || !incompleteOrders.length) return;
    return () => {
      for (const incompleteOrder of incompleteOrders) {
        const { id } = incompleteOrder;
        cancelOrder(id);
      }
    };
  }, [cancelOrder, incompleteOrders]);

  useEffect(() => {
    const responsiveViewCallback = () => {
      if (width > mobile) return;
      const shoppingCartDrawer: HTMLDivElement | null = document?.querySelector(
        ".ShoppingCartDrawer"
      );
      const payButton: HTMLDivElement | null = document?.querySelector(
        ".ShoppingCartDrawer__pay-button"
      );
      if (!shoppingCartDrawer || !payButton) return;
      const modalHeight = shoppingCartDrawer.clientHeight;
      const bottom = modalHeight - height;
      shoppingCartDrawer.style.paddingBottom = `${bottom}px`;
      payButton.style.bottom = `${bottom}px`;
    };

    const timeout = setTimeout(responsiveViewCallback, 1000);

    return () => clearTimeout(timeout);
  }, [height, width, opened]);

  return (
    <Styles
      className="ShoppingCartDrawer"
      opened={opened}
      onClose={closeHandler}
    >
      <CloseSVG className="ShoppingCartDrawer__close" onClick={closeHandler} />
      <div className="ShoppingCartDrawer__title">
        Carrito
        {WITH_SHARE_SHOPPING_CART && !empty ? (
          <ShareButton
            className="ShoppingCartDrawer__share"
            config={{
              title: `Compartir carrito`,
              text: `Comparte tu carrito con otra persona`,
              url: `${getFullPath()}?shareId=${uid}`
            }}
          />
        ) : null}
      </div>
      <div className="ShoppingCartDrawer__store">
        <StoreSVG />
        {selectedStore?.storeName}
      </div>
      {empty ? (
        <EmptyCart />
      ) : (
        <>
          <div className="ShoppingCartDrawer__cart">
            {WITH_COUPONS ? (
              <RedeemCoupon className="ShoppingCartDrawer__coupon" />
            ) : null}
            {selectedBenefit ? (
              <CouponDetail
                inCart
                removeError={removeBenefitError}
                benefit={selectedBenefit}
                onRemove={removeHandler}
              />
            ) : null}
            <div className="ShoppingCartDrawer__order">
              <p className="ShoppingCartDrawer__order__title">Tu orden</p>
              <p className="ShoppingCartDrawer__order__total">
                {totals
                  ? `${formatByCurrency(
                      totals.total,
                      currencyOptionsDefault(currency, 0)
                    )}`
                  : "$0.00"}
              </p>
            </div>
            <Button
              type="LINK"
              className="ShoppingCartDrawer__empty-button"
              onClick={emptyCartHandler}
            >
              Vaciar carrito
            </Button>
            <CartProducts disabled={disabled} />
            <Button
              type="LINK"
              className="ShoppingCartDrawer__add-button"
              onClick={() => router.push("/categories")}
            >
              <PlusSVG /> Continuar agregando al carrito
            </Button>
            <ShoppingCartSummary className="ShoppingCartDrawer__summary" />
          </div>
          <div className="ShoppingCartDrawer__pay-button">
            <CartPayButton
              empty={empty}
              setDisabled={setDisabled}
              shoppingCart={shoppingCart}
              setErrors={setErrors}
            />
          </div>
        </>
      )}
      {recommendedProducts?.length && WITH_PURCHASE ? (
        <UpsaleModal
          opened={openUpsale}
          onSuccess={() => setOpenUpsale(false)}
          products={recommendedProducts}
          onClose={() => setOpenUpsale(false)}
        />
      ) : null}
    </Styles>
  );
};

ShoppingCartDrawer.defaultProps = {};

export default ShoppingCartDrawer;
