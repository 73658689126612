import React from "react";

import Styles from "./ProductAbout.styles";
import { ProductAboutProps as Props } from "./ProductAbout.types";
import { getDescription } from "components/product/Product/Product.helpers";

const ProductAbout: React.FC<Props> = props => {
  const { product, className } = props;
  const { description = "" } = product ?? {};

  return (
    <Styles className={`ProductAbout ${className}`}>
      <h2 className="ProductAbout__title">Sobre mi</h2>
      {getDescription(description, "ProductAbout__description")}
    </Styles>
  );
};

ProductAbout.defaultProps = {
  className: ""
};

export default ProductAbout;
