import React from "react";

import Styles from "./ProductError.styles";
import { ProductErrorProps as Props } from "./ProductError.types";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";

import { useRouter } from "next/router";

import ErrorSVG from "../../../../public/assets/images/Errors/pad-error.svg";

const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;

const ProductError: React.FC<Props> = props => {
  const { push } = useRouter();

  return (
    <Styles className="ProductError" isModal={WITH_PRODUCT_MODAL}>
      <div className="ProductError__error-state">
        <div className="ProductError__error-state__icon">
          <ErrorSVG />
        </div>
        <p className="ProductError__error-state__title">¡Upsss!</p>
        <p className="ProductError__error-state__description">
          ¡No se pudo cargar esta página!
        </p>
        <Button
          className="ProductError__error-state__button"
          onClick={() => push("/")}
        >
          Volver a inicio
        </Button>
      </div>
    </Styles>
  );
};

ProductError.defaultProps = {};

export default ProductError;
