import styled from "styled-components";

import { ProductStyledProps as Props } from "./Product.types";
import { ProductPlaceholderStyledProps as PlaceholderProps } from "./Product.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { desktop, tablet, mobile } = BREAKPOINTS;

const ProductStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  padding-top: ${props =>
    props.talkShop ? "1.6rem" : `var(--sizes-navbar-height)`};
  background-color: var(--palette-cream-s42-l93);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-areas:
    ".      header ."
    ".      main   ."
    "footer footer footer";

  @media (max-width: ${tablet}px) {
    padding-top: ${props =>
      props.talkShop
        ? `calc(var(--sizes-navbar-height-mobile) - env(safe-area-inset-top))`
        : `var(--sizes-navbar-height-mobile-without-search)`};
  }

  & .Pocket {
    &__header {
      padding: 0.8rem 0;
      grid-area: header;

      &__title {
        font-size: 1.6rem;
        color: var(--palette-black-s0-l10);
      }
    }
  }

  .Product {
    &__header {
      .Product__shareButton {
        position: relative;
        top: -0.1rem;

        .Clickable {
          padding: 0;
        }
      }
    }

    &__navbar {
      @media (max-width: ${tablet}px) {
        display: grid;
      }
    }

    &__main {
      grid-area: main;

      @media (max-width: ${mobile}px) {
        grid-column: 1 / -1;
      }
    }

    &__topActions {
      display: flex;
      align-items: center;
      width: 50%;
      justify-content: space-between;
      padding: 2.4rem 0;

      @media (max-width: ${mobile}px) {
        padding-bottom: 0.8rem;
        margin-top: 3.2rem;
      }

      @media (max-width: 851px) {
        width: 100%;
      }

      .Product__shareButton {
        display: block;

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__shareButton {
      display: flex;
      justify-content: flex-end;

      @media (min-width: ${tablet + 1}px) {
        display: none;
      }
    }

    &__gallery {
      padding-bottom: 1.6rem;
      border-bottom: 0.8rem solid var(--palette-gray-s0-l98);
      margin-bottom: 1.6rem;

      @media (min-width: ${tablet}px) {
        display: none;
      }
    }

    &__slider {
      padding-bottom: 14rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__wishlist-button {
      margin-left: auto;

      path {
        fill: var(--palette-primary);
      }
    }

    &__category-gallery {
      padding-bottom: 12rem;

      @media (max-width: ${tablet}px) {
        padding-bottom: 2.4rem;
      }
    }

    &__footer {
      grid-area: footer;
      padding-bottom: 10rem;
      background-color: var(--palette-purple-s66-l84);
    }
  }

  && .ProductBaseInfo {
    &__name {
      white-space: normal;
    }
  }
`;

export const ProductPlaceholderStyled = styled.div<PlaceholderProps>`
  .ProductPlaceholder {
    width: 100%;

    &__info {
      width: 100%;
      background-color: var(--pallete-cream-s42-l93);
      display: grid;
      gap: 1.5rem;
      grid-template-columns: 15rem 56rem 2fr;
      grid-template-rows: repeat(3, 15rem);
      grid-template-areas:
        "miniBox1 bigBox status"
        "miniBox2 bigBox status"
        "miniBox3 bigBox share";

      @media (max-width: ${desktop}px) {
        justify-content: center;
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(3, 15rem);
        grid-template-rows: repeat(8, 15rem);
        grid-template-areas:
          "bigBox bigBox bigBox"
          "bigBox bigBox bigBox"
          "bigBox bigBox bigBox"
          "miniBox1 miniBox2 miniBox3"
          "status status status"
          "status status status"
          "share share share"
          "share share share";
      }

      @media (max-width: ${mobile}px) {
        grid-template-columns: repeat(3, 9rem);
        grid-template-rows: repeat(10, 9rem);
        grid-template-areas:
          "bigBox bigBox bigBox"
          "bigBox bigBox bigBox"
          "bigBox bigBox bigBox"
          "miniBox1 miniBox2 miniBox3"
          "status status status"
          "status status status"
          "status status status"
          "share share share"
          "share share share"
          "share share share";
      }
    }

    &__info > div {
      border-radius: 2.4rem;
    }

    &__miniBox1 {
      grid-area: miniBox1;
    }

    &__miniBox2 {
      grid-area: miniBox2;
    }

    &__miniBox3 {
      grid-area: miniBox3;
    }

    &__bigBox {
      grid-area: bigBox;
    }

    &__status {
      grid-area: status;

      @media (max-width: ${desktop}px) {
        margin-top: 3rem;
        height: 25rem;
      }

      @media (max-width: ${mobile}px) {
        margin-top: 3rem;
        height: unset;
      }
    }

    &__share {
      grid-area: share;

      @media (max-width: ${desktop}px) {
        margin-top: 3rem;
        height: 18rem;
      }

      @media (max-width: ${mobile}px) {
        margin-top: 3rem;
        height: unset;
      }
    }
  }
`;

export default ProductStyled;
