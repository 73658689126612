import styled from "styled-components";

import Button from "components/global/Button/Button";
import { AddToWishlistButtonStyledProps as Props } from "./AddToWishlistButton.types";

const AddToWishlistButtonStyled = styled(Button)<Props>`
  &.AddToWishlistButton {
    padding: 1.3rem 1.3rem;

    &:hover:not([disabled]) {
      svg {
        path {
          fill: var(--palette-red-s85-l45);
        }
      }
    }

    &--active {
      background-color: var(--palette-red-s85-l45);
    }
  }

  .AddToWishlistButton {
    &__text {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--palette-text-primary);
    }
  }
`;

export default AddToWishlistButtonStyled;
