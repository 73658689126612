import styled from "styled-components";

import { CouponsModalStyledProps as Props } from "./CouponsModal.types";
import { CouponModalHeaderStyledProps } from "./CouponsModal.types";
import { getMaxHeight } from "utils/styling.utils";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const CouponsModalStyled = styled.div<Props>`
  height: min(${getMaxHeight()}, 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${mobile}px) {
    padding: 0 1.6rem;
  }

  .CouponsModal {
    &__input {
      padding-left: 2.4rem;
      padding-right: 2.4rem;

      @media (max-width: ${mobile}px) {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      }
    }

    &__error {
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
    }

    &__apply-error {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }

    &__redeem-error {
      padding-top: 0.8rem;
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }

    &__divider {
      width: 100%;
      height: 0.4rem;
      margin: 2rem 0rem;
    }

    &__apply {
      display: flex;
      flex-direction: column;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      padding-bottom: 1.6rem;

      &__button {
        width: 100%;
      }
    }
  }
`;

export const ModalHeaderStyled = styled.div<CouponModalHeaderStyledProps>`
  display: flex;
  align-items: center;
  padding: 1.6rem 2.4rem;
  font-weight: 600;
  font-size: 2rem;
  color: var(--palette-black-s0-l10);
`;

export default CouponsModalStyled;
