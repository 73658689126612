import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { ShareButton, Clickable } from "artisn-ui-react";
import { events } from "@artisan-commerce/analytics-capacitor";
import { CartProduct } from "artisn/types";
import { validateShoppingCart } from "artisn/shopping-cart";
import { ValidateShoppingCartResult } from "artisn/shopping-cart";

import CONSTANTS from "config/constants";
import Styles from "./ProductMain.styles";
import ProductDetailsImage from "../ProductDetailsImage/ProductDetailsImage";
import ProductBaseInfo from "components/global/ProductBaseInfo/ProductBaseInfo";
import AddToWishlistButton from "components/products/AddToWishlistButton/AddToWishlistButton";
import AddToCartButton from "components/products/AddToCartButton/AddToCartButton";
import ProductPlaceholder from "../Product/Product.placeholder";
import Counter from "components/global/Counter/Counter";
import { ProductMainProps as Props } from "./ProductMain.types";
import { useProductForm } from "hooks/useProductForm";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import { getFullPath, scrollToElement } from "utils/common.utils";
import { getBenefitProductId } from "utils/common.utils";
import ProductError from "../ProductError/ProductError";
import useProducts from "contexts/products/products.context.hooks";
import { createAddToWishlistNotification } from "utils/notifications.utils";
import { dismissAddToWishlistNotification } from "utils/notifications.utils";
import { useAddToWishlist } from "components/products/AddToWishlistButton/AddToWishListButton.hooks";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";
import useOnUnmount from "hooks/useOnUnmount";
import useAuth from "contexts/auth/auth.context.hooks";
import Button from "components/global/Button/Button";
import ProductAbout from "../ProductAbout/ProductAbout";
import { shouldMock } from "utils/common.utils";
import { getProductTags } from "../Product/Product.helpers";

import HeartSVG from "../../../../public/assets/images/icons/black-heart.svg";
import FilledHeartSVG from "../../../../public/assets/images/heart-primary-filled.svg";
import PadIconBlackSVG from "../../../../public/assets/images/ProductMain/pad-icon-black.svg";
import HandIconSVG from "../../../../public/assets/images/ProductMain/hand-icon.svg";

const { ARTISN, FEATURE_FLAGS } = CONSTANTS;
const { WITH_SHARE_PRODUCT } = FEATURE_FLAGS;
const { WITH_WISHLIST } = FEATURE_FLAGS;
const { SHOPPING_CART_WISHLIST_NAME } = ARTISN;
const { SHOPPING_CART_DEFAULT_NAME } = ARTISN;
const { logViewProductDetails } = events.product;

const enhancedConfig = {
  shoppingCartName: SHOPPING_CART_WISHLIST_NAME
};

const ProductMain: React.FC<Props> = props => {
  const { productId, product, isLoading, isError, isSignedIn } = props;
  const { className } = props;
  const { isModal = false } = props;
  const { isAnonymous } = useAuth();
  const { setSelectedProduct } = useProducts();
  const { amount: selectedProductAmount } = (product as CartProduct) ?? {};
  const { push, query } = useRouter();
  const { storeId, vendorId } = query ?? {};
  const [amount, setAmount] = useState(selectedProductAmount ?? 1);
  const { analyticsInitialized } = useAnalytics();
  const viewProductDetailsLogged = useRef(false);
  const { images, categories } = product ?? {};
  const { available, outOfStock } = product ?? {};
  const { name, tags = "" } = product ?? {};
  const tagsArray = getProductTags(tags);
  const form = useProductForm(product);
  const [category] = categories ?? [];
  const { categoryId } = category ?? {};
  const selectedProductId = product?.productId;
  const ref = useRef(selectedProductId);
  const productRef = useRef(product);
  const [disable, setDisable] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { onClick: addToWishlist, inCart } = useAddToWishlist({
    product,
    config: enhancedConfig
  });
  const { shoppingCart, temporalBenefit } = useShoppingCart();
  const icon = inCart ? <FilledHeartSVG /> : <HeartSVG />;
  const { benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const benefitProductId = getBenefitProductId(
    temporalBenefit,
    selectedBenefit
  );
  const isBenefit = benefitProductId === selectedProductId;
  const [, setShowStoreNotification] = useState(false);
  const [, setErrors] = useState<ValidateShoppingCartResult>();
  const [isAddToCart, setIsAddToCart] = useState(false);

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      addToWishlist();
      dismissAddToWishlistNotification();
    },
    [addToWishlist]
  );

  const addToCartFinishHandler = () => {
    setIsAddToCart(true);
  };

  const errorHandler = () => {
    if (!form) {
      return;
    }
    const errorGroup = form.renderer.find(group => group.status !== "OK");
    if (!errorGroup) {
      return;
    }
    scrollToElement(`modifier-group-${errorGroup.id}`, isModal);
  };

  const addProductHandler = async () => {
    if (!product || disable) return;
    setDisable(true);
    await addToWishlist();
    if (!inCart) setShowNotification(true);
    setDisable(false);
  };

  const shareButtonNode = WITH_SHARE_PRODUCT ? (
    <ShareButton
      className="ProductModal__share"
      config={{
        title: `Compartir información del animal de compañía`,
        text: `Comparte información del animal de compañía con otra persona`,
        url: `${getFullPath()}?storeId=${storeId}&vendorId=${vendorId}`
      }}
    />
  ) : null;

  const productActionsNode = () => (
    <section className="ProductMain__actions">
      <Counter
        className="ProductMain__counter"
        min={1}
        max={isBenefit ? 1 : undefined}
        maxDisabled={!!isBenefit}
        initialValue={selectedProductAmount ?? 1}
        onChange={value => setAmount(value)}
        disabled={!available}
      />
      <AddToCartButton
        className="ProductMain__addToCart"
        onError={errorHandler}
        config={{
          amount
        }}
        form={form}
        onFinish={addToCartFinishHandler}
        isAddToCart={isAddToCart}
        disabled={!available || !product?.attributes.showInMenu}
      />
    </section>
  );

  const heartNode = () => {
    if (!WITH_WISHLIST) return;
    return (
      <Clickable
        className="ProductMain__wishlist-heart"
        onClick={addProductHandler}
      >
        {icon}
      </Clickable>
    );
  };

  const mobileButtonsNode = () => {
    return (
      <div className="ProductMain__buttons ProductMainButtonsOverlay">
        <p className="ProductMain__buttons__title">
          Hola mi nombre es ¡{name}!
        </p>
        <AddToWishlistButton
          className="ProductMain__buttons-wishlist"
          color={false}
          product={product}
          categoryId={categoryId}
        />
        <AddToCartButton
          className="ProductMain__buttons-cart"
          form={form}
          onError={errorHandler}
          onFinish={addToCartFinishHandler}
          isAddToCart={isAddToCart}
          disabled={!available || !product?.attributes.showInMenu}
        />
      </div>
    );
  };

  useEffect(() => {
    if (!product || viewProductDetailsLogged.current || !analyticsInitialized) {
      return;
    }
    logViewProductDetails({
      product
    });
    viewProductDetailsLogged.current = true;
  }, [analyticsInitialized, product]);

  useEffect(() => {
    setAmount(selectedProductAmount ?? 1);
  }, [selectedProductAmount]);

  useEffect(() => {
    if (!productRef.current || !productId) return;
    if (productId !== productRef.current?.productId) {
      setSelectedProduct(undefined);
    }
  }, [productId, setSelectedProduct]);

  useEffect(() => {
    ref.current = selectedProductId;
  }, [selectedProductId]);

  useEffect(() => {
    if (!inCart || !showNotification) return;
    dismissAddToWishlistNotification();
    createAddToWishlistNotification(product, onDelete);
    setShowNotification(false);
  }, [inCart, product, onDelete, showNotification]);

  useOnUnmount(() => setSelectedProduct(undefined));

  useEffect(() => {
    (async () => {
      if (!shoppingCart || !isAddToCart) return;
      if (!Object.keys(shoppingCart.stores).length) return;
      const validate = !shouldMock ? validateShoppingCart : () => undefined;
      try {
        const errors = await validate({
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          anonymous: isAnonymous
        });
        const { products, stores } = errors ?? {};
        stores?.forEach(store => {
          const { type } = store;
          if (type === "IS_OPEN" && stores.length === 1) {
            setShowStoreNotification(true);
          }
        });

        const hasErrors = !!products?.length;
        if (hasErrors && !shouldMock) {
          setErrors(errors);
          return;
        }
        setIsAddToCart(false);
        push("/checkout?redirect=false");
      } catch (e) {
        console.error(e.message);
      }
    })();
  }, [isAnonymous, shoppingCart, isAddToCart, push]);

  return (
    <Styles className={`ProductMain ${className}`} isModal={isModal}>
      <div
        className={isError || isLoading ? undefined : "ProductMain__content"}
      >
        {!isLoading && product?.questions && form && productId ? (
          <>
            <div className="ProductMain__preview-imageContainer">
              <section className="ProductMain__preview-image">
                <ProductDetailsImage images={images} />
              </section>
              {!outOfStock ? (
                <section className="ProductMain__about ProductMain__about--desktop">
                  <ProductAbout product={product} />
                  <HandIconSVG className="ProductMain__hand" />
                </section>
              ) : null}
            </div>
            <div className="ProductMain__formContainer">
              <section className="ProductMain__form">
                {!outOfStock ? (
                  <>
                    <div className="ProductMain__baseInfo-icons">
                      <ProductBaseInfo
                        product={product}
                        isAdopted={false}
                        category={category}
                      />
                      {!isLoading && !isError && product && isModal ? (
                        <div className="ProductMain__icons">
                          {heartNode()}
                          {shareButtonNode}
                        </div>
                      ) : null}
                    </div>
                    <div className="ProductMain__description">
                      <h3 className="ProductMain__description__text">
                        Características
                      </h3>
                      <div className="ProductMain__description__tags">
                        {tagsArray?.length &&
                          tagsArray.map((tag, i) => {
                            return (
                              <div
                                className="ProductMain__description__tag"
                                key={i}
                              >
                                {tag}
                              </div>
                            );
                          })}
                      </div>
                      <AddToCartButton
                        className="ProductMain__description__button"
                        form={form}
                        onError={errorHandler}
                        onFinish={addToCartFinishHandler}
                        isAddToCart={isAddToCart}
                        disabled={!available || !product?.attributes.showInMenu}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="ProductMain__baseInfo-icons">
                      <ProductBaseInfo product={product} isAdopted />
                      {!isLoading && !isError && product && isModal ? (
                        <div className="ProductMain__icons">
                          {heartNode()}
                          {shareButtonNode}
                        </div>
                      ) : null}
                    </div>
                    <div className="ProductMain__description">
                      <h3 className="ProductMain__description__text">
                        Características
                      </h3>
                      <div className="ProductMain__description__tags">
                        {tagsArray?.length &&
                          tagsArray.map((tag, i) => {
                            return (
                              <div
                                className="ProductMain__description__tag"
                                key={i}
                              >
                                {tag}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}
              </section>
              {!outOfStock ? (
                <section className="ProductMain__about ProductMain__about--mobile">
                  <ProductAbout product={product} />
                  <HandIconSVG className="ProductMain__hand" />
                </section>
              ) : null}
              <section className="ProductMain__share" id="modifiers">
                <div>
                  <PadIconBlackSVG className="ProductMain__share__icon" />
                  <h2 className="ProductMain__share__title">
                    ¡Ayuda a correr la voz!
                  </h2>
                  <p className="ProductMain__share__text">
                    Con una simple acción puedes hacer que un compañero
                    encuentre una familia
                  </p>
                </div>
                <div className="ProductMain__share__buttonContainer">
                  <Button className="ProductMain__share__button">
                    Compartir
                    {shareButtonNode}
                  </Button>
                </div>
              </section>
            </div>

            {isModal ? (
              <section className="ProductMain__wish-button">
                {WITH_WISHLIST && !isLoading && !isError && product ? (
                  <>
                    <AddToWishlistButton
                      className="ProductMain__wishlist-button"
                      product={product}
                      categoryId={categoryId}
                      disabled={!isSignedIn}
                    />
                  </>
                ) : null}
                {WITH_SHARE_PRODUCT && !isLoading && !isError && product ? (
                  <>{shareButtonNode}</>
                ) : null}
              </section>
            ) : null}
            {isModal ? productActionsNode() : null}
          </>
        ) : null}

        {isLoading && !isError ? <ProductPlaceholder /> : null}
        {!isLoading && isError && !product ? <ProductError /> : null}
        {mobileButtonsNode()}
      </div>
    </Styles>
  );
};

ProductMain.defaultProps = {
  className: ""
};

export default ProductMain;
