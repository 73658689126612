// Product Page helper functions and data

export const breadcrumbItems = [
  {
    name: "Home",
    path: "/"
  },
  {
    name: "Categorías",
    path: "/categories"
  }
];

export const productImageConfig = {
  width: 300,
  height: 300
};

export const getProductTags = (tags: string | string[] | undefined) => {
  if (!tags) return;
  if (Array.isArray(tags)) return tags;
  return tags.split(",");
};

export const getDescription = (description: string, className: string) => {
  if (!description) return;
  const descriptionLinesArray = description?.split("/");

  return (
    <div className={className}>
      {descriptionLinesArray?.map((line, index) => {
        return index + 1 !== descriptionLinesArray?.length ? (
          <p key={index}>
            {line}
            <br />
            <br />
          </p>
        ) : (
          <p key={index}>{line}</p>
        );
      })}
    </div>
  );
};
