import React from "react";

import Styles from "./ProductBaseInfo.styles";
import { ProductBaseInfoProps as Props } from "./ProductBaseInfo.types";

import PuddleIconSVG from "../../../../public/assets/images/puddle-icon.svg";
import AddToWishlistButton from "components/products/AddToWishlistButton/AddToWishlistButton";

const ProductBaseInfo: React.FC<Props> = props => {
  const { product, category, className, isAdopted } = props;
  const { categoryId } = category ?? {};

  const { name } = product;

  return (
    <Styles className={`ProductBaseInfo ${className}`}>
      <div className="ProductBaseInfo__container">
        {!isAdopted ? (
          <>
            <h1 className="ProductBaseInfo__name">
              ¡Hola mi nombre es {name}!
            </h1>
            <AddToWishlistButton
              color={false}
              className="ProductBaseInfo__heart"
              product={product}
              categoryId={categoryId}
            />
          </>
        ) : (
          <>
            <h1 className="ProductBaseInfo__adopted">
              ¡{name} ya fue adoptado por alguien más!
            </h1>
            <PuddleIconSVG className="ProductBaseInfo__icon" />
          </>
        )}
      </div>
    </Styles>
  );
};

ProductBaseInfo.defaultProps = {
  className: ""
};

export default ProductBaseInfo;
