import styled from "styled-components";

import { ProductAboutStyledProps as Props } from "./ProductAbout.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const ProductAboutStyled = styled.div<Props>`
  .ProductAbout {
    &__title {
      font-size: 2rem;
      font-weight: 600;
      padding-bottom: 2.6rem;

      @media (max-width: ${tablet}px) {
        width: auto;
        min-width: none;
      }
    }

    &__description {
      line-height: 2.4rem;
    }
  }
`;

export default ProductAboutStyled;
