import styled from "styled-components";

import { ProductMainStyledProps as Props } from "./ProductMain.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile, desktop } = BREAKPOINTS;

const ProductMainStyled = styled.div<Props>`
  && .ProductMain {
    &__content {
      display: grid;
      grid-template-columns: 2fr 1fr;

      @media (max-width: 1200px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    &__wishlist-heart {
      padding: 0;
    }

    &__icons {
      display: flex;
    }

    &__preview-imageContainer {
      margin-right: 1.6rem;

      @media (max-width: 1200px) {
        margin-right: 0;
      }
    }

    &__preview-image {
      flex: 1 0 0;
      display: flex;
      justify-content: center;
      position: relative;
      align-items: flex-start;

      @media (max-width: ${tablet}px) {
        width: auto;
        min-width: none;
      }

      @media (max-width: ${mobile}px) {
        margin-bottom: 5rem;
      }

      & .ProductDetailsImage {
        position: sticky;
        top: 8rem;
        justify-content: start;
      }
    }

    &__formContainer {
      @media (max-width: ${tablet}px) {
        width: 100%;
      }

      @media (max-width: ${mobile}px) {
        width: 100%;
      }
    }

    &__form {
      width: 50rem;
      max-width: 50rem;
      max-height: max-content;
      background: var(--palette-white);
      border-radius: 1.8rem;
      padding: 3.5rem;

      @media (max-width: 1200px) {
        margin-top: 5rem;
        max-width: unset;
        width: unset;
      }

      @media (max-width: ${desktop}px) {
        max-width: unset;
      }

      @media (max-width: ${tablet}px) {
        max-width: unset;
      }

      @media (max-width: ${mobile}px) {
        width: 100%;
        margin-top: 0;
      }
    }

    &__baseInfo-icons {
      display: ${props => (props.isModal ? "none" : "flex")};
      justify-content: center;

      .ProductModal {
        &__share {
          margin-left: 1.6rem;

          .Clickable {
            padding: 0;
          }
        }
      }

      @media (max-width: ${tablet}px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__share {
      background-color: var(--palette-blue-s51-l16);
      border-radius: 2.4rem;
      padding: 2.2rem 1.7rem;
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-gap: 1.5rem;
      margin-top: 5rem;
      position: relative;

      @media (max-width: ${tablet}px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      @media (max-width: ${mobile}px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 1.6rem;
        margin-left: 1.6rem;
      }

      &__icon {
        position: absolute;
        top: -3rem;
        left: 0.3rem;
      }

      &__title {
        color: var(--palette-white);
        font-size: 2.4rem;
      }

      &__text {
        color: var(--palette-white);
        line-height: 2.4rem;
        margin-top: 0.4rem;
      }

      &__buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__button {
        padding: 1.7rem 2rem;
        border-radius: 10rem;
        font-weight: 600;
        font-size: 1.6rem;
        position: relative;

        .ProductModal__share {
          position: absolute;
          width: 100%;

          .Clickable {
            width: 100%;
            border-radius: 10rem;

            svg {
              display: none;
            }
          }
        }
      }
    }

    &__description {
      &__text {
        color: var(--palette-gray-s0-l70);
        margin-top: 4rem;
      }

      &__tags {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin-top: 1rem;
        margin-right: 1.4rem;
        position: relative;
        z-index: 1;
        flex-wrap: wrap;
      }

      &__tag {
        background-color: var(--palette-purple-s100-l99);
        padding: 0.8rem;
        margin: 0.5rem;
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .AddToCartButton__btn__no-ok {
        border-radius: 10rem;
        height: 5rem;
        width: 100%;
        margin-top: 4rem;
        font-weight: 600;

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__about {
      padding: 2.4rem;
      background-color: var(--palette-white);
      border-radius: 2.4rem;
      max-width: 75rem;
      margin-top: 6rem;
      margin-right: 2.8rem;
      position: relative;

      &--mobile {
        display: none;
      }

      @media (max-width: 1200px) {
        margin-left: 2.8rem;

        &--desktop {
          display: none;
        }

        &--mobile {
          display: block;
        }
      }

      @media (max-width: ${tablet}px) {
        margin: 4.8rem 0;
      }
    }

    &__hand {
      position: absolute;
      top: -2.8rem;
      right: 2.4rem;
    }

    &__comment-box {
      padding-top: 4.8rem;
    }

    &__wishlist-button {
      margin-left: auto;

      path {
        fill: var(--palette-primary);
      }
    }

    &__actions {
      display: flex;
      align-items: center;

      @media (max-width: ${tablet}px) {
        position: fixed;
        display: flex;
        justify-content: space-around;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--palette-white);
        padding: 1.2rem 0.4rem;
        z-index: 3;
        align-items: center;
        box-shadow: 0 -0.1rem 0.8rem 0.1rem var(--palette-gray-s0-l20-a20);
      }
    }

    &__addToCart .Button {
      @media (max-width: ${tablet}px) {
        flex-wrap: wrap;
        display: flex;
        align-self: center;
        padding: 0.8rem;
      }
    }

    &__counter {
      margin-right: 3.2rem;

      & .Counter {
        &__button {
          width: 3.2rem;
          height: 3.2rem;
        }
      }

      @media (max-width: ${tablet}px) {
        display: flex;
        align-self: center;
        order: -1;
      }

      @media (max-width: ${mobile}px) {
        margin-right: 0;
      }
    }

    &__buttons {
      display: none;

      @media (max-width: ${tablet}px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 1.6rem;
        z-index: 1;
      }

      &__title {
        font-weight: 600;
        max-width: 14.4rem;
      }

      &-wishlist {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid var(--palette-cream-s42-l93);
        background-color: var(--palette-gray-white);
        border-radius: 1rem;
        max-height: 4.4rem;
        margin: 0 1.6rem;

        &--active {
          background-color: var(--palette-primary);
        }
      }

      .AddToCartButton__btn__no-ok {
        border-radius: 10rem;
        height: 5.6rem;
        width: 12.8rem;
        font-weight: 600;
      }
    }
  }
`;

export default ProductMainStyled;
