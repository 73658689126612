import React, { useCallback, useEffect, useRef, useState } from "react";
import { MouseEvent } from "react";
import { ProductCard as ProductCardUI } from "artisn-ui-react";
import { useProductCard } from "artisn-ui-react";
import { events } from "@artisan-commerce/analytics-capacitor";

import Styles from "./ProductCard.styles";
import { ProductCardProps as Props } from "./ProductCard.types";
import ProductCardPlaceholder from "./ProductCard.placeholder";
import { goToProductDetail, sanitizeText } from "utils/seo.utils";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import useCountries from "contexts/countries/countries.hooks";
import ProductModal from "../ProductModal/ProductModal";
import CONSTANTS from "config/constants";
import ShoppingCartDrawer from "components/global/drawer/ShoppingCartDrawer/ShoppingCartDrawer";
import CrossaleModal from "../CrossaleModal/CrossaleModal";
import { useFetchRecommendedProducts } from "services/products/products.service.hooks";
import AddToWishlistButton from "components/products/AddToWishlistButton/AddToWishlistButton";
import { Pet, PetDetails } from "components/product/Product/Product.types";
import { getDomain } from "utils/common.utils";
import useCities from "contexts/cities/cities.hooks";
import Button from "components/global/Button/Button";

import IconSmallUbication from "../../../../public/assets/images/icon-small-ubication.svg";

const { Description, Image, Name } = ProductCardUI;
const { logProductImpression, logSelectProduct } = events.product;
const { FEATURE_FLAGS, ARTISN } = CONSTANTS;
const { WITH_PRODUCT_MODAL, WITH_CROSSALE_MODAL } = FEATURE_FLAGS;
const { WITH_CART_DRAWER, WITH_PURCHASE } = FEATURE_FLAGS;
const { DEFAULT_IMAGE } = ARTISN;

const ProductCard: React.FC<Props> = props => {
  const { product, width = 216, height = 328, icon, className } = props;
  const { category } = props;
  const { storeId, vendorId } = product ?? {};
  const { tags, description } = product ?? {};
  const petDescription = description.split("/").join("");
  const { carousel = false } = props;
  const enhancedProduct = !carousel
    ? {
        ...product,
        images: product.images[0] ? [product.images[0]] : [],
        description: petDescription
      }
    : { ...product, description: petDescription };
  const { decimals } = useCountries().selectedCountry;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { available } = useProductCard(product, { decimals });
  const { analyticsInitialized } = useAnalytics();
  const productCardRef = useRef<HTMLAnchorElement>(null);
  const [opened, setOpened] = useState(false);
  const { data: recommendedProducts } = useFetchRecommendedProducts();
  const [openCrossale, setOpenCrossale] = useState(false);
  const [productId, setProductId] = useState("");
  const { selectedCity } = useCities();
  const { name: city } = selectedCity ?? {};

  const onOpenDrawer = () => {
    setOpened(true);
  };

  const callback = useCallback<IntersectionObserverCallback>(
    entries => {
      const [entry] = entries;
      if (!entry.isIntersecting || !analyticsInitialized) return;
      logProductImpression({
        product
      });
    },
    [analyticsInitialized, product]
  );

  const onClickModal = () => {
    setIsOpenModal(true);
  };

  const onSuccess = () => {
    if (WITH_PRODUCT_MODAL) {
      onClickModal();
    } else {
      goToProductDetail(enhancedProduct, storeId, vendorId);
    }
  };

  const selectedProductHandler = (product: Pet) => {
    const { storeId, vendorId } = (product as PetDetails) ?? {};
    setProductId(product.productId);
    if (WITH_PRODUCT_MODAL) {
      onClickModal();
    } else {
      goToProductDetail(product, storeId, vendorId);
    }
    setOpenCrossale(false);
  };

  const onClickHandler = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setProductId(enhancedProduct.productId);
    if (WITH_CROSSALE_MODAL && recommendedProducts?.length) {
      setOpenCrossale(true);
      return;
    }
    logSelectProduct({
      product
    });
    if (WITH_PRODUCT_MODAL) {
      onClickModal();
      return;
    }
    goToProductDetail(enhancedProduct, storeId, vendorId);
  };

  useEffect(() => {
    const productCard = productCardRef.current;
    if (!productCard) return;

    const observer = new IntersectionObserver(callback, { threshold: 0.7 });
    observer.observe(productCard);

    return () => observer.unobserve(productCard);
  }, [callback]);

  return (
    <Styles
      ref={productCardRef}
      className={`ProductCard ${className}`}
      available={available}
      href={`${getDomain()}/pets/${enhancedProduct.productId}/${sanitizeText(
        enhancedProduct.name
      )}?storeId=${storeId}&vendorId=${vendorId}`}
      //@ts-ignore
      onClick={onClickHandler}
    >
      <ProductCardUI
        className="ProductCard__card"
        product={enhancedProduct}
        placeholder={<ProductCardPlaceholder />}
        width={`${width}px`}
        height={`${height}px`}
      >
        <AddToWishlistButton
          color
          product={product}
          categoryId={category?.categoryId}
          className="ProductCard__heart"
        />
        <Image
          className={`${
            enhancedProduct.images.length
              ? "ProductCard__image"
              : "ProductCard__image-default"
          }`}
          width={width - 16}
          height={width - 16}
          infinite
          alt={enhancedProduct.name}
          controls={false}
          pagination
          fallbackImage={DEFAULT_IMAGE}
          errorImage={DEFAULT_IMAGE}
        />
        <div className="ProductCard__tags">
          {tags?.map((tag, i) => {
            return (
              <div className="ProductCard__tag" key={i}>
                {tag}
              </div>
            );
          })}
        </div>
        <Name className="ProductCard__name" />
        <Description className="ProductCard__description" />
        {icon}
        <div className="ProductCard__container">
          <div className="ProductCard__container--location">
            <IconSmallUbication />
            <p className="ProductCard__location">{city?.toLowerCase()}</p>
          </div>
          <Button
            type="LINK"
            className="ProductCard__container--button"
            onClick={() =>
              goToProductDetail(enhancedProduct, storeId, vendorId)
            }
          >
            Ver más
          </Button>
        </div>
      </ProductCardUI>
      {WITH_PRODUCT_MODAL ? (
        <ProductModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          productId={productId}
          onOpenDrawer={onOpenDrawer}
        />
      ) : null}
      {WITH_CART_DRAWER && WITH_PURCHASE ? (
        <ShoppingCartDrawer
          opened={opened}
          onClose={() => setOpened(prev => !prev)}
        />
      ) : null}
      {WITH_CROSSALE_MODAL && recommendedProducts?.length && WITH_PURCHASE ? (
        <CrossaleModal
          opened={openCrossale}
          onSuccess={onSuccess}
          products={recommendedProducts}
          onClose={() => setOpenCrossale(false)}
          onClick={selectedProductHandler}
        />
      ) : null}
    </Styles>
  );
};

ProductCard.defaultProps = {
  className: ""
};

export default ProductCard;
