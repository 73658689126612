import React, { useState } from "react";
import { applyBenefit, getShoppingCartProducts } from "artisn/shopping-cart";
import { Benefit } from "artisn/types";

import CONSTANTS from "config/constants";
import useGeo from "contexts/geo/geo.hooks";
import Modal from "components/global/Modal/Modal";
import { goToBenefitDetail } from "utils/seo.utils";
import useShippingCost from "hooks/useShippingCost";
import Button from "components/global/Button/Button";
import BenefitsList from "../BenefitsList/BenefitsList";
import Divider from "components/global/Divider/Divider";
import Styles, { ModalHeaderStyled } from "./CouponsModal.styles";
import { CouponsModalProps as Props } from "./CouponsModal.types";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";
import RedeemCouponInput from "components/Cart/RedeemCoupon/RedeemCouponInput/RedeemCouponInput";
import useAuth from "contexts/auth/auth.context.hooks";
import { notify } from "utils/common.utils";

import CloseSVG from "../../../../public/assets/images/close.svg";

const { SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;
const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;

const CouponsModal: React.FC<Props> = props => {
  const { opened, benefits, className, redeemCouponError } = props;
  const { setRedeemCouponError, onClose, redeemHandler } = props;
  const { openProductModal } = props;
  const { isAnonymous } = useAuth();
  const { shoppingCart, setTemporalBenefit } = useShoppingCart();
  const shippingCost = useShippingCost();
  const { selectedCoordinates } = useGeo();
  const { lat, lng } = selectedCoordinates ?? {};

  const [couponCode, setCouponCode] = useState("");
  const [selectedBenefit, setSelectedBenefit] = useState<Benefit>();
  const [applyBenefitError, setApplyBenefitError] = useState("");
  const empty = benefits && benefits.length === 0;
  const { benefits: cartBenefits } = shoppingCart ?? {};
  const selectedCartBenefit = cartBenefits ? cartBenefits[0] : undefined;
  const disable = empty || !selectedBenefit || !!selectedCartBenefit;

  const resetLocalStates = () => {
    setSelectedBenefit(undefined);
    setApplyBenefitError("");
    setRedeemCouponError("");
    setCouponCode("");
  };

  const closeHandler = () => {
    resetLocalStates();
    onClose?.();
  };

  const applyHandler = async () => {
    if (!selectedBenefit || !shoppingCart) return;

    try {
      setApplyBenefitError("");
      const { benefitId, type } = selectedBenefit;
      if (type === "PRODUCT") {
        setTemporalBenefit(selectedBenefit);

        if (WITH_PRODUCT_MODAL) {
          closeHandler();
          openProductModal();
          return;
        }
        goToBenefitDetail(selectedBenefit);
        return;
      }

      if (
        type === "ALTER_DELIVERY" ||
        type === "DISCOUNT_FIXED" ||
        type === "DISCOUNT_PERCENTAGE"
      ) {
        setTemporalBenefit(undefined);
        if (!getShoppingCartProducts(shoppingCart).length) {
          throw new Error(
            "Can't apply benefit because there are no products in the cart"
          );
        }

        await applyBenefit({
          benefitId,
          latitude: lat,
          longitude: lng,
          shippingCost,
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          anonymous: isAnonymous,
          shoppingCartId: shoppingCart.id
        });
        closeHandler();
      }
    } catch (e) {
      notify(e, "Apply benefit");
      setApplyBenefitError(e.message);
    }
  };

  const modalHeaderNode = (
    <ModalHeaderStyled>
      <p className="CouponsModal__header">Cupones disponibles</p>
    </ModalHeaderStyled>
  );

  return (
    <Modal
      {...props}
      closeOnClickOutside
      opened={opened}
      header={modalHeaderNode}
      closeIcon={<CloseSVG />}
      backdropConfig={{ onClick: closeHandler }}
      onClose={closeHandler}
    >
      <Styles className={`CouponsModal ${className}`}>
        <div className="CouponsModal__header">
          <div className="CouponsModal__input">
            <RedeemCouponInput
              disabled={!benefits}
              couponCode={couponCode}
              onRedeem={redeemHandler}
              setCouponCode={setCouponCode}
            />
          </div>
          {redeemCouponError ? (
            <p className="CouponsModal__error CouponsModal__redeem-error">
              {redeemCouponError}
            </p>
          ) : null}
          <Divider className="CouponsModal__divider" />
        </div>
        <BenefitsList
          benefits={benefits}
          selectedBenefit={selectedBenefit}
          setSelectedBenefit={setSelectedBenefit}
        />
        <div className="CouponsModal__apply">
          {applyBenefitError ? (
            <p className="CouponsModal__error CouponsModal__apply-error">
              {applyBenefitError}
            </p>
          ) : null}
          <Button
            type="FILLED"
            color="primary"
            className="CouponsModal__apply__button"
            disabled={disable}
            onClick={applyHandler}
          >
            Aplicar
          </Button>
        </div>
      </Styles>
    </Modal>
  );
};

CouponsModal.defaultProps = {
  className: ""
};

export default CouponsModal;
