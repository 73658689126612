import React, { useMemo } from "react";
import { ShoppingCartSummary as ShoppingCartSummaryUI } from "artisn-ui-react";
import { getShoppingCartTotal } from "artisn/shopping-cart";

import Styles from "./ShoppingCartSummary.styles";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";
import { ShoppingCartSummaryProps as Props } from "./ShoppingCartSummary.types";
import CONSTANTS from "config/constants";
import useDefaultCurrencyFormatter from "hooks/useDefaultCurrencyFormatter";

const { ShippingCost, Total } = ShoppingCartSummaryUI;
const { Subtotal, Discount, Taxes, Points } = ShoppingCartSummaryUI;
const { WITH_LOYALTY } = CONSTANTS.FEATURE_FLAGS;

const ShoppingCartSummary: React.FC<Props> = props => {
  const { className } = props;
  const { shoppingCart } = useShoppingCart();
  const shoppingCartTotal = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartTotal(shoppingCart);
  }, [shoppingCart]);
  const defaultFormatter = useDefaultCurrencyFormatter();

  if (!shoppingCartTotal) return null;

  return (
    <Styles
      className={`ShoppingCartSummary ${className}`}
      shoppingCartTotals={shoppingCartTotal}
      formatter={defaultFormatter}
    >
      <Subtotal />
      <Discount />
      <Taxes />
      {WITH_LOYALTY ? <Points /> : null}
      <ShippingCost />
      <Total />
    </Styles>
  );
};

ShoppingCartSummary.defaultProps = {
  className: ""
};

export default ShoppingCartSummary;
