import styled from "styled-components";

import { DividerStyledProps as Props } from "./Divider.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const DividerStyled = styled.div<Props>`
  background-color: var(--palette-gray-s0-l85);
  height: ${props => (props.showMobile ? "0" : "0.8rem")};
  margin: 2rem 0;

  @media (max-width: ${tablet}px) {
    height: 0.8rem;
  }

  .Divider {
  }
`;

export default DividerStyled;
