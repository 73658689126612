import styled from "styled-components";
import { ShoppingCartSummary } from "artisn-ui-react";

import { ShoppingCartSummaryStyledProps as Props } from "./ShoppingCartSummary.types";

const ShoppingCartSummaryStyled = styled(ShoppingCartSummary)<Props>`
  .ShoppingCartSummary {
  }
`;

export default ShoppingCartSummaryStyled;
