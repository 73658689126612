import React from "react";

import { ProductPlaceholderStyled as Styles } from "./Product.styles";
import { ProductPlaceholderProps as Props } from "./Product.types";

const ProductPlaceholder: React.FC<Props> = props => {
  return (
    <Styles className="ProductPlaceholder">
      <div className="ProductPlaceholder__info">
        <div className="ProductPlaceholder__miniBox1 loading-shine" />
        <div className="ProductPlaceholder__miniBox2 loading-shine" />
        <div className="ProductPlaceholder__miniBox3 loading-shine" />
        <div className="ProductPlaceholder__bigBox loading-shine" />
        <div className="ProductPlaceholder__status loading-shine" />
        <div className="ProductPlaceholder__share loading-shine" />
      </div>
    </Styles>
  );
};

ProductPlaceholder.defaultProps = {};

export default ProductPlaceholder;
