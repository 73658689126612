import styled from "styled-components";

import { ProductBaseInfoStyledProps as Props } from "./ProductBaseInfo.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;

const ProductBaseInfoStyled = styled.div<Props>`
  .ProductBaseInfo {
    &__container {
      display: flex;
      position: relative;
    }

    &__name {
      font-size: 3.6rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
      line-height: 4.2rem;
      text-overflow: clip;
      max-width: 35rem;

      @media (max-width: ${mobile}px) {
        font-size: 3rem;
      }
    }

    &__adopted {
      font-size: 3.6rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
      line-height: 4.2rem;
      text-overflow: clip;
      max-width: 55rem;
    }

    &__heart {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      margin-left: 3rem;
      margin-right: 2rem;
      border: 0.1rem solid var(--palette-cream-s42-l93);
      background-color: var(--palette-gray-white);
      border-radius: 1rem;
      max-height: 4.3rem;

      &--active {
        background-color: var(--palette-red-s85-l45);
      }

      &:hover:not([disabled]) {
        path {
          fill: var(--palette-white);
        }
      }

      @media (max-width: ${mobile}px) {
        margin-right: 1rem;
      }
    }

    &__icon {
      position: absolute;
      top: -8rem;
      left: -2rem;
    }
  }
`;

export default ProductBaseInfoStyled;
